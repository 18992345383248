import React, { useState } from 'react';
import { Modal, TextInput, NumberInput, Button, Center } from '@mantine/core';

const QuoteRequestModal = ({ isModalOpen, setModalOpen, formData }) => {
    const [isFormValid, setIsFormValid] = useState(false);

    const [quoteData, setQuoteData] = useState({
        name: '',
        email: '',
        phone: '',
        orgName: '',
        orgSize: '',
    });

    const handleInputChange = (event) => {
        const newQuoteData = { ...quoteData, [event.target.name]: event.target.value };
        setQuoteData(newQuoteData);
    
        // Validate the form
        const isValid = newQuoteData.name && newQuoteData.email && newQuoteData.orgName;
        setIsFormValid(isValid);
    };
    
    const handleSubmit = () => {
        fetch('https://lead-collector-slack.onrender.com/send-quote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: quoteData.name,
                email: quoteData.email,
                phone: quoteData.phone,
                orgName: quoteData.orgName,
                orgSize: quoteData.orgSize,
                formData: formData, // Include the form data
            }),
        })
        .then(response => response.text())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    
        setModalOpen(false);
    };
    

    return (
        <Modal opened={isModalOpen} onClose={() => setModalOpen(false)} title="Request a Quote">
            <TextInput required label="Name" name="name" value={quoteData.name} onChange={handleInputChange} />
            <TextInput required label="Email" name="email" value={quoteData.email} onChange={handleInputChange} />
            <TextInput label="Phone Number" name="phone" value={quoteData.phone} onChange={handleInputChange} />
            <TextInput required label="Organization Name" name="orgName" value={quoteData.orgName} onChange={handleInputChange} />
            <NumberInput label="Organization Size" name="orgSize" value={quoteData.orgSize} onChange={(value) => setQuoteData({ ...quoteData, orgSize: value })} />
            <Center>
            <Button 
        style={{ margin: '20px' }} 
        onClick={handleSubmit}
        disabled={!isFormValid} // Disable button if the form is not valid
    >
        Submit
    </Button>
            </Center>
        </Modal>
    );
};

export default QuoteRequestModal;
