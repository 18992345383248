import React, { useState } from 'react';
import { NumberInput, Select, Switch, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const ManagedITCostForm = () => {
    const [deviceCount, setDeviceCount] = useState(10);
    const [serviceLevel, setServiceLevel] = useState('Basic');
    const [include24x7Support, setInclude24x7Support] = useState(false);
    const [additionalFeatures] = useState([]);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);


    // Placeholder for estimation logic
    const calculateEstimate = () => {
        const baseRatePerDevice = 50; // Example rate per device
        let cost = deviceCount * baseRatePerDevice;

        // Adjust cost based on service level
        const serviceLevelMultiplier = serviceLevel === 'Advanced' ? 1.5 : 1;
        cost *= serviceLevelMultiplier;

        // Add cost for 24/7 support
        if (include24x7Support) {
            cost = cost * 1.4; // Example flat fee for 24/7 support
        }

        // Add cost for additional features
        const additionalFeaturesCost = additionalFeatures.length * 50; // Example cost per feature
        cost += additionalFeaturesCost;

        setEstimatedCost(cost);
    };

    return (
        <div style={{ marginTop: '20px', margin: '20px' }}>
        <NumberInput
                label="Number of devices"
                description="Enter the total number of devices to be managed"
                min={1}
                value={deviceCount}
                onChange={setDeviceCount}
            />

            <Select
                label="Service Level"
                data={['Basic', 'Advanced']}
                value={serviceLevel}
                onChange={setServiceLevel}
            />

            <Switch
                label="Include 24/7 Support"
                checked={include24x7Support}
                onChange={(event) => setInclude24x7Support(event.currentTarget.checked)}
            />

<Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
                <Button onClick={calculateEstimate}>Calculate</Button>
                <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
            </Group>


                <Text size="lg" weight={500}>
                Estimated Monthly Price: ${(.90 * estimatedCost).toFixed(0)} - ${(1.4 * estimatedCost).toFixed(0)}
                </Text>


            <QuoteRequestModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                formData={{ deviceCount, serviceLevel, include24x7Support, estimatedCost }}
            />
        </div>
    );
};

export default ManagedITCostForm;
