import React, { useState } from 'react';
import { MantineProvider, Button, Modal, Image, Paper, Center, Text, Container, Tabs } from '@mantine/core';
import WebAppPenTestForm from './WebAppPenTestForm'; // Import the component here
import BusinessRiskAssessmentForm from './BusinessRiskAssessmentForm'; // Import the component here
import NetworkPenTestForm from './NetworkPenTestForm';
import FullTimeSecurityEmployeeForm from './FullTimeSecurityEmployeeForm';
import FractionalCISOForm from './FractionalCISOForm'
import ManagedITCostForm from './ManagedITCostForm';
import ManagedSecurityCostForm from './ManagedSecurityCostForm';
import { Document, Page } from 'react-pdf'




function App() {

  const [isModalOpen, setModalOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState('');

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const toggleModal = (file) => {
    setPdfFile(file);
    setModalOpen(!isModalOpen);
  };


  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      
      <center>
        <a href="https://adversis.io" target="_blank" rel="noopener noreferrer">
        <Image
  radius="md"
  style={{
    height: '35vh', // Use viewport height for responsive height
    maxWidth: '100%', // Max width to contain the image within the parent
    objectFit: 'contain', // To maintain aspect ratio
  }}
  src={process.env.PUBLIC_URL + '/logo.png'}
  alt="Logo" // Alt text for accessibility
/>

        </a>
      </center>

      <Container size="sm" padding="md">
        <Paper padding="md" shadow="sm">
          <Text fs="italic" weight={700} size="xs">
            This estimator is based on industry averages and is intended to provide a rough estimate of the cost of cybersecurity services. Please contact us for a more accurate quote.
          </Text>
          <Tabs defaultValue="webapp" >
            <Tabs.List>
              <Tabs.Tab value="webapp">Web Application Penetration Testing</Tabs.Tab>
              <Tabs.Tab value="businessrisk">Business Risk Assessment</Tabs.Tab>
              <Tabs.Tab value="network">Network Penetration Test</Tabs.Tab>
              <Tabs.Tab value="fte">Full Time Security Employee</Tabs.Tab>
              <Tabs.Tab value="fractional">Fractional CISO</Tabs.Tab>
              <Tabs.Tab value="managedit">Managed IT</Tabs.Tab>
              <Tabs.Tab value="managedsecurity">Managed Security</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="webapp" pt="xs">
              <WebAppPenTestForm />
            </Tabs.Panel>
            <Tabs.Panel value="businessrisk" pt="xs">
              <BusinessRiskAssessmentForm />
            </Tabs.Panel>
            <Tabs.Panel value="network" pt="xs">
              <NetworkPenTestForm />
            </Tabs.Panel>
            <Tabs.Panel value="fte" pt="xs">
              <FullTimeSecurityEmployeeForm />
            </Tabs.Panel>
            <Tabs.Panel value="fractional" pt="xs">
              <FractionalCISOForm />
            </Tabs.Panel>
            <Tabs.Panel value="managedit" pt="xs">
              <ManagedITCostForm />
            </Tabs.Panel>
            <Tabs.Panel value="managedsecurity" pt="xs">
              <ManagedSecurityCostForm />
            </Tabs.Panel>
            {/* ... other panels with values corresponding to their tabs */}
          </Tabs>
        </Paper>
        <Center>
          <Text fw={700}>Example Reports</Text>
        </Center>
        <Center>
          <Button
            style={{ marginTop: '20px', margin: '5px' }}
            variant="gradient"
            gradient={{ from: 'blue', to: 'violet', deg: 135 }}
            radius="lg"
            onClick={() => toggleModal('pentest.pdf')}
          >
            Penetration Test
          </Button>
          <Button
            style={{ marginTop: '20px', margin: '5px' }}
            variant="gradient"
            gradient={{ from: 'indigo', to: 'pink', deg: 90 }}
            radius="lg"
            onClick={() => toggleModal('businessassessment.pdf')}
          >
            Business Risk Assessment
          </Button>
        </Center>
      </Container>
      <Modal
        opened={isModalOpen}
        onClose={() => setModalOpen(false)}
        size="750"
      >
        <Document file={pdfFile} className="pdf-document" 
         onLoadSuccess={onDocumentLoadSuccess}
>
          {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
          style={{ marginBotton: '0px' }}
          scale={1.2}               
          renderAnnotationLayer={false} 
          renderTextLayer={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
        </Document>

      </Modal>
    </MantineProvider>

  );
}

export default App;
