import React, { useState } from 'react';
import { Select, MultiSelect, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const FullTimeSecurityEmployeeForm = () => {
  const [jobLevel, setJobLevel] = useState('Junior');
  const [skills, setSkills] = useState([]);
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);



  // Placeholder for a more complex estimation logic
  const calculateEstimate = () => {
    const baseSalary = { 'Junior': 60000, 'Mid-level': 80000, 'Senior': 100000 };
    let skillMultiplier = 1 + skills.length * 0.05; // 5% increase per skill

    let salary = baseSalary[jobLevel] * skillMultiplier;
    
    setEstimatedCost(salary);
  };

  return (
    <div style={{ marginTop: '20px', margin: '20px' }}>
      <Select
        label="Job Level"
        placeholder="Select level"
        data={['Junior', 'Mid-level', 'Senior']}
        value={jobLevel}
        onChange={setJobLevel}
      />

      <MultiSelect
        label="Required Skills"
        data={['Network Security', 'Incident Response', 'Security Auditing', 'Compliance Management', 'Penetration Testing']}
        value={skills}
        onChange={setSkills}
        placeholder="Select skills"
      />

<Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
        <Button onClick={calculateEstimate}>Calculate</Button>
        <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
      </Group>



        <Text size="lg" weight={500}>
        Estimated Annual Cost: ${(.90 * estimatedCost).toFixed(0)} - ${(1.4 * estimatedCost).toFixed(0)}
        </Text>


      <QuoteRequestModal 
  isModalOpen={isModalOpen} 
  setModalOpen={setModalOpen} 
  formData={{ jobLevel, skills, estimatedCost }}
/>
    </div>
  );
};

export default FullTimeSecurityEmployeeForm;
