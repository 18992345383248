import React, { useState } from 'react';
import { Select, Button, Group, Text } from '@mantine/core';
import CustomTooltip from './CustomToolTip'; // Import the CustomTooltip component
import QuoteRequestModal from './QuoteRequestModal';



const FractionalCISOForm = () => {
  const [companySize, setCompanySize] = useState('Small');
  const [industry, setIndustry] = useState('Technology');
  const [estimatedCost, setEstimatedCost] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  // Placeholder for a more complex estimation logic
  const calculateEstimate = () => {
    const baseRate = 250; // average hourly rate for a CISO consultant
    let sizeMultiplier = 1;
    let industryMultiplier = 1;

    // Adjust the multiplier based on the company size
    switch (companySize) {
      case 'Small':
        sizeMultiplier = 1;
        break;
      case 'Medium':
        sizeMultiplier = 1.25;
        break;
      case 'Large':
        sizeMultiplier = 1.5;
        break;
      default:
        sizeMultiplier = 1;
        break;
    }

    // Adjust the multiplier based on the industry
    switch (industry) {
      case 'Technology':
        industryMultiplier = 1.1;
        break;
      case 'Finance':
        industryMultiplier = 1.2;
        break;
      case 'Healthcare':
        industryMultiplier = 1.3;
        break;
      // Add more industries as needed
      default:
        industryMultiplier = 1;
        break;
    }

    // Calculate the estimated monthly cost based on hours per week
    const weeklyCost = baseRate * 4;
    const monthlyCost = weeklyCost * 4 * sizeMultiplier * industryMultiplier; // Assuming 4 weeks per month
    setEstimatedCost(monthlyCost);
  };

  return (
    <div style={{ marginTop: '20px', margin: '20px' }}>
              <CustomTooltip>

      <Select
        label="Company Size"
        placeholder="Select size"
        data={['Small', 'Medium', 'Large']}
        value={companySize}
        onChange={setCompanySize}
      />
      </CustomTooltip>

      <Select
        label="Industry"
        placeholder="Select industry"
        data={['Technology', 'Finance', 'Healthcare']}
        value={industry}
        onChange={setIndustry}
      />

<Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
        <Button onClick={calculateEstimate}>Calculate</Button>
        <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
      </Group>


        <Text size="lg" weight={500}>
        Estimated Monthly Price: ${(.90 * estimatedCost).toFixed(0)} - ${(1.4 * estimatedCost).toFixed(0)}
        </Text>

  
      <QuoteRequestModal 
  isModalOpen={isModalOpen} 
  setModalOpen={setModalOpen} 
  formData={{ companySize, industry, estimatedCost }}
/>
    </div>
  );
};

export default FractionalCISOForm;
