import React from 'react';
import { Tooltip } from '@mantine/core';

const CustomToolTip = ({ children }) => {
  const companySizeInfo = `
    Small: Fewer than 50 employees. \n
    Medium: 50-250 employees. \n
    Large: More than 250 employees.
  `;

  return (
    <Tooltip label={companySizeInfo}      
        styles={{
        tooltip: {
          backgroundColor: '#757575', // Grey background
          color: 'white', // White text color
          fontSize: '0.85em', // Adjust font size if needed
        },
        arrow: {
          backgroundColor: '#757575', // Match arrow color with tooltip
        }
    }}>
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
