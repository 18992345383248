import React, { useState } from 'react';
import { NumberInput, Select, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const NetworkPenTestForm = () => {
    const [deviceCount, setDeviceCount] = useState(10);
    const [networkComplexity, setNetworkComplexity] = useState('Low');
    const [estimatedPrice, setEstimatedPrice] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);


    // Placeholder for a more complex estimation logic
    const calculateEstimate = () => {
        const basePricePerDevice = 50; // arbitrary base price per device
        let complexityMultiplier = 1;

        switch (networkComplexity) {
            case 'Low':
                complexityMultiplier = 1;
                break;
            case 'Medium':
                complexityMultiplier = 1.2;
                break;
            case 'High':
                complexityMultiplier = 1.5;
                break;
            default:
                complexityMultiplier = 1;
                break;
        }

        let price = deviceCount * basePricePerDevice * complexityMultiplier;
        // if price is less than 1500, set to 1500 since that is typically a mimumum
        price = price < 1500 ? 1500 : price;
        //if price is greater than 100000, set to 100000 since that is typically a maximum
        price = price > 100000 ? 100000 : price;


        setEstimatedPrice(price);
    };

    return (
        <div style={{ marginTop: '20px', margin: '20px' }}>
        <NumberInput
                label="Number of devices in the network"
                description="Enter the total number of devices that require testing"
                placeholder="e.g., 50"
                min={1}
                value={deviceCount}
                onChange={setDeviceCount}
            />

            <Select
                label="Network Complexity"
                placeholder="Select complexity"
                data={['Low', 'Medium', 'High']}
                value={networkComplexity}
                onChange={setNetworkComplexity}
            />

<Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
                <Button onClick={calculateEstimate}>Calculate</Button>
                <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
            </Group>


                <Text size="lg" weight={500}>
                Estimated Price: ${(.90 * estimatedPrice).toFixed(0)} - ${(1.4 * estimatedPrice).toFixed(0)}
                </Text>


            <QuoteRequestModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                formData={{ deviceCount, networkComplexity, estimatedPrice }}
            />
        </div>
    );
};

export default NetworkPenTestForm;
