import React, { useState } from 'react';
import { NumberInput, Select, CheckboxGroup, Checkbox, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';


const ManagedSecurityCostForm = () => {
    const [deviceCount, setDeviceCount] = useState(10); // Added for device count
    const [securityLevel, setSecurityLevel] = useState('Standard');
    const [additionalFeatures, setAdditionalFeatures] = useState([]);
    const [estimatedCost, setEstimatedCost] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);


    // Placeholder for estimation logic
    const calculateEstimate = () => {
        const baseRatePerDevice = 100; // Example rate per device
        let cost = deviceCount * baseRatePerDevice; // Cost is now based on device count

        // Adjust cost based on security level
        const securityLevelMultiplier = securityLevel === 'Advanced' ? 1.5 : 1;
        cost *= securityLevelMultiplier;

        // Add cost for additional features
        const additionalFeaturesCost = additionalFeatures.length * 100; // Example cost per feature
        cost += additionalFeaturesCost;

        setEstimatedCost(cost);
    };

    return (
        <div style={{ marginTop: '20px', margin: '20px' }}>
        <NumberInput
                label="Number of Devices"
                description="Enter the total number of devices to be secured"
                min={1}
                value={deviceCount}
                onChange={setDeviceCount}
            />

            <Select
                label="Security Level"
                data={['Standard', 'Advanced']}
                value={securityLevel}
                onChange={setSecurityLevel}
            />

            <CheckboxGroup
                label="Additional Features"
                value={additionalFeatures}
                onChange={setAdditionalFeatures}
                orientation="vertical"
            >
                <Checkbox value="Feature1" label="Intrusion Detection System" />
                <Checkbox value="Feature2" label="24/7 Monitoring" />
                <Checkbox value="Feature3" label="Regular Security Audits" />
                {/* Add more checkboxes for additional features as needed */}
            </CheckboxGroup>

            <Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
                <Button onClick={calculateEstimate}>Calculate</Button>
                <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
            </Group>


                <Text size="lg" weight={500}>
                Estimated Monthly Price: ${(.90 * estimatedCost).toFixed(0)} - ${(1.4 * estimatedCost).toFixed(0)}
                </Text>

     
            <QuoteRequestModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                formData={{ deviceCount, securityLevel, additionalFeatures, estimatedCost }}
            />
        </div>
    );
};

export default ManagedSecurityCostForm;
