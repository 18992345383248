import React, { useState } from 'react';
import {  Select, Checkbox, Button, Group, Text } from '@mantine/core';
import QuoteRequestModal from './QuoteRequestModal';
import CustomTooltip from './CustomToolTip'; // Import the CustomTooltip component



const BusinessRiskAssessmentForm = () => {
  const [businessSize, setBusinessSize] = useState('Small');
  const [regulatoryOversight, setRegulatoryOversight] = useState(false);
  const [sensitiveDataLevel, setSensitiveDataLevel] = useState('Low');
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);


  // Placeholder for a more complex estimation logic
  // Placeholder for a more complex estimation logic
  const calculateEstimate = () => {
    const baseRate = 250; // average hourly rate
    let multiplier = 1; // Start with a base multiplier of 1

    // Adjust multiplier based on business size
    switch (businessSize) {
      case 'Small':
        multiplier *= 1.0;
        break;
      case 'Medium':
        multiplier *= 1.4;
        break;
      case 'Large':
        multiplier *= 1.9;
        break;
      default:
        multiplier *= 1;
        break;
    }

    // Increase multiplier if there is regulatory oversight
    if (regulatoryOversight) {
      multiplier *= 1.3; // Increase by 30% for regulatory complexity
    }

    // Adjust multiplier based on the level of sensitive data
    switch (sensitiveDataLevel) {
      case 'Low':
        multiplier *= 1;
        break;
      case 'Medium':
        multiplier *= 1.4;
        break;
      case 'High':
        multiplier *= 1.9;
        break;
      default:
        multiplier *= 1;
        break;
    }

    // Calculate final estimated price
    const estimate = baseRate * multiplier * 12; 
    setEstimatedPrice(estimate);
  };

  return (
    <div style={{ marginTop: '20px', margin: '20px' }}>
      <CustomTooltip>
      <Select
        label="Business Size"
        placeholder="Select size"
        data={['Small', 'Medium', 'Large']}
        value={businessSize}
        onChange={setBusinessSize}
      />
      </CustomTooltip>

      <Checkbox
        label="Regulatory Oversight"
        description="Check if the business is under regulatory oversight such as HIPAA."
        checked={regulatoryOversight}
        onChange={(event) => setRegulatoryOversight(event.currentTarget.checked)}
      />

      <Select
        label="Level of Sensitive Data"
        placeholder="Select level"
        data={['Low', 'Medium', 'High']}
        value={sensitiveDataLevel}
        onChange={setSensitiveDataLevel}
      />
      <Group spacing="md" style={{ marginTop: '20px', margin: '5px' }}>
        <Button onClick={calculateEstimate}>Calculate</Button>
        <Button variant="outline" onClick={() => setModalOpen(true)}>Request Quote</Button>
      </Group>


        <Text size="lg" weight={500}>
          Estimated Price: ${(.90 * estimatedPrice).toFixed(0)} - ${(1.4 * estimatedPrice).toFixed(0)}
        </Text>
      <QuoteRequestModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        formData={{ businessSize, regulatoryOversight, sensitiveDataLevel, estimatedPrice }}
      />
    </div>
  );
};

export default BusinessRiskAssessmentForm;
